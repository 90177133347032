import axios from "axios";
import {
  URL,
  URLStackTwo
} from "../config/config";






//getVendorPlayersList
export const getVendorPlayersList = (data) => {

  return axios.post(URL + `/getVendorPlayersList`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
  });

};


//createPlayer
export const createPlayer = (data) => {

  return axios.post(URL + `/createPlayer`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
  });

};

// register Team
export const registerTeam = (data) => {

  return axios.post(URL + `/registerTeam`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
  });

}
