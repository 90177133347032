// Base URLS
  //  export const URL = "http://192.168.99.229:3000/dev";
  //  export const URLStackTwo = "http://192.168.99.229:3000/dev";
  //  export const URLUSER = "http://192.168.99.229:3000/dev";

// Live URL
  // export const URL = "https://ua8wqqx491.execute-api.us-west-2.amazonaws.com/dev";
  // export const URLStackTwo = "https://2durx62msk.execute-api.us-west-2.amazonaws.com/dev";

  // With NEW DB
  // export const URL = "https://rn2teeyb89.execute-api.us-west-2.amazonaws.com/dev";
  // export const URLStackTwo = "https://frptt1q2vc.execute-api.us-west-2.amazonaws.com/dev";
  // export const URLUSER = "https://b5acv8zn6a.execute-api.us-west-2.amazonaws.com/dev";

  //   With dicota-dev environment
// export const URLStackTwo = "https://41l9gjuiml.execute-api.us-west-2.amazonaws.com/dev";
export const URL = "https://85a6j1nxdf.execute-api.us-west-2.amazonaws.com/dev";
export const URLStackTwo = "https://41l9gjuiml.execute-api.us-west-2.amazonaws.com/dev";
export const URLUSER = "https://b5acv8zn6a.execute-api.us-west-2.amazonaws.com/dev";