import { UserId} from '../actions/actionTypes';
const initialState = {
  userId: '',
  accessId:''
};
const UserIdReducer=(state=initialState,action)=>{

  if(action.type === UserId){
    return action.payload
  }
  return state;
};
export default UserIdReducer;
