import axios from "axios";
import { URL ,URLStackTwo} from "../config/config";



//getTags
export const GetTournamentByVendor = (data) => {

    return axios.post(URL + `/getAllVendorsTournaments`,data, {
        // headers: {
        //     accessToken: localStorage.getItem('accessId')
        // }
    });

};
export const getTournamentDetails = (data) => {
    return axios.post(URL + `/getTournamentDetails`,data, {
        // headers: {
        //     accessToken: localStorage.getItem('accessId')
        // }
    });

};

// generate Rounds
export const generateRounds = (data) => {
    return axios.post(URL + `/GenerateRoundsRoutine`, data);
};

//createTournament
export const createTournament = (data) => {

    return axios.post(URL + `/createTournament`, data);

};

//editTournament
export const editTournament = (data) => {

    return axios.post(URL + `/editTournament`, data);

};


//GetTournament
export const GetTournament = (data) => {

    return axios.get(URL + `/getTournament`+ `/` + data);

};

//getLeaderboard
export const getLeaderboard = (data) => {

    return axios.get(URLStackTwo + `/getLeaderboard`+ `/` + data);

};

export const getTeamsByTournamentd = (data) => {

    return axios.get(URLStackTwo + `/getTeamsByTournamentd`+ `/` + data);

};

export const getMatchByTournamentd = (data) => {

    return axios.post(URLStackTwo + `/getAllMatchesByTournamentId` , data);

};
export const getPlayersByTeamId = (data) => {

    return axios.post(URLStackTwo + `/getPlayersByTeamID`, data);

};

export const getTournamentScheduler = (data) => {

    return axios.post(URLStackTwo + `/getTournamentScheduler`, data);

};






