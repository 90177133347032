import axios from "axios";
import { URL,URLStackTwo } from "../config/config";



//getGames
export const getGames = (data) => {
  
    return axios.get(URL + `/getGames`+ `/` + data, {
      // headers: {
      //     accessToken: localStorage.getItem('accessId')
      // }
  });

  };


  
//addGame
export const addGame = (data) => {
  
  return axios.post(URL + `/createGame`, data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
});

};



//editGame
export const editGame = (data) => {
  
  return axios.post(URL + `/editGame`,data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
});

};


//deleteGame
export const deleteGame = (data) => {
  
  return axios.delete(URL + `/deleteGame`+ `/` + data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
});

};


//getGame
export const getGame = (data) => {
  
  return axios.get(URL + `/getGame`+ `/` + data, {
    // headers: {
    //     accessToken: localStorage.getItem('accessId')
    // }
});

};

//addGame
export const getSignInUrl = (data) => {
  
  return axios.post(URL + `/getSignInUrl`, data);

};


//resetDatabase
export const resetDatabase = (data) => {
  
  return axios.get(URLStackTwo + `/reSetDatabase`);

};