
module.exports = {
    WRONGPASSWORD:"Wrong password",
    NO_USER_FOUND:"No User Found",
    INVALID_PASSWORD:"You have entered wrong password",
    INVALID_EMAIL:"You have entered wrong Email",
    SUCCESSFUL_LOGIN:"Login Successfull!",
    STATUS_CODE_200:200,
    STATUS_CODE_201:201,
    STATUS_CODE_404:404,
    STATUS_CODE_400:400,
    STATUS_CODE_401:401,
    STATUS_CODE_500:500,
    manager:"manager",
    DATAEDITED:"Data updated successfully",
    DATAADDED:"Data added successfully",
    GAMEEDITEDMESSAGE:"Game Details updated successfully",
    TOURNAMENTEDITEDMESSAGE:"Tournament Details updated successfully",
    GAMEADDEDMESSAGE:"Game Details added successfully",
    TOURNAMENTADDEDMESSAGE:"Tournament Details added successfully",
    comingSoon:'This feature will be available soon',
    warning:'warning',
    Session:"Session",
    Session_Expired:" Your Session has been expired. Please Sign in",
    user:"user",
    samePassword:"Both passwords are sames",
    superAdmin:"superAdmin",
    ACTIVE:"Active",
    sameInputs:"No change in inputs",
    Game:"Game",
    Tournament:"Tournament",
    Scheduler:"Scheduler",
    DEACTIVE:"deActive",
    LOGINFAILED:"Login Unsuccessful",
    Error_MESSAGE:"error",
    success:"success",
    SUCCESSMESSAGE:"success",
    CreateUser:"CreateUser",
    CreateTag:"CreateTag",
    UpdateTag:"UpdateTag",
    EditUser:"EditUser",
    UPDATED_DATA:"Data has successfully updated",
    EditUserSuccess:"User has been Edited Successfully",
    User:"User",
    Login:"Login",
    ResetPassword:"Login",
    ForgetPassword:'Forget Password',
    successForget:"Successfully! Sent the verification Code to your Email",
    successReset:"Your password has been changed Sucessfully!",
    captchaError:"kindly apply captcha",
    Register:"Registration",
    successRegistration:"A verification Email has been sent to your provided Email",
    captchaSuccess:"Captcha has been verified sucessfully",
    successFetch:"Successfully Fetched Users",
  Upcoming:"Upcoming",
  Completed:"Completed",
  Disabled:"Disabled",
  Scheduled:"Scheduled",

    Priority: [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "3",
            label: "3"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "5",
            label: "5"
        }
    ],
    TOURNAMENT_STATUS: [
        {
            value: "Active",
            label: "Active"
        },
        {
            value: "Upcoming",
            label: "Upcoming"
        },
        {
            value: "Completed",
            label: "Completed"
        },
        {
            value: "Disabled",
            label: "Disabled"
        },
    ],
    TOURNAMENT_STATUS_ADD: [
        {
            value: "Upcoming",
            label: "Upcoming"
        }
    ],
    LEVELS: [
        {
            value: 1,
            label: 1
        },
        {
            value: 2,
            label: 2
        },
        {
            value: 3,
            label: 3
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 5,
            label: 5
        },
    ],
    NO_OF_TEAMS: [
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
    ],
    NO_OF_PLAYERS: [
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
      
        
    ],
    NO_OF_TEAMPLAYERS: [
        {
            value: "1",
            label: "1"
        },
        {
            value: "2",
            label: "2"
        },
        {
            value: "4",
            label: "4"
        },
        {
            value: "8",
            label: "8"
        },
        {
            value: "16",
            label: "16"
        },
        {
            value: "32",
            label: "32"
        }
      
        
    ],
    Color: [
        {
            value: "Red",
            label: "Red"
        },
        {
            value: "Green",
            label: "Green"
        },
        {
            value: "Yellow",
            label: "Yellow"
        },
        {
            value: "Oranage",
            label: "Oranage"
        },
        {
            value: "Maroon",
            label: "Maroon"
        }
    ],
    NO_OF_PLAYERSPERMATCH: [
        {
            value: 2,
            label: 2
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 6,
            label: 6
        },
        {
            value: 8,
            label: 8
        },
        {
            value: 10,
            label: 10
        }
      
        
    ],



}
