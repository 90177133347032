import {addGame} from  '../actions/actionTypes';
import {editGame} from  '../actions/actionTypes';
const initialState = {
  mode:"",
  itemId:""
}

const gameReducer=(state=initialState,action)=>{
  if(action.type === editGame){
    return action.payload
  }
  else if(action.type === addGame){
    return action.payload
  }
  return state;
}
export default gameReducer;
