
import {lOGIN_TRUE} from  '../actions/actionTypes';
const initialState = {
  login: ''
};


const loginReducer=(state=initialState,action)=>{

  if(action.type === lOGIN_TRUE){
    return action.payload
  }
  return state;
};
export default loginReducer;
