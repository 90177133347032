export const GET_LIST = 'GET_LIST'
export const GET_LIST_SUCCESS = 'GET_LIST_SUCCESS'
export const GET_LIST_FAILURE = 'GET_LIST_FAILURE';
export const UserId = 'UserId';
export const ForgetPass = 'forgetPass';
export const addGame = 'addgame';
export const editGame = 'editgame';
export const Edit_USER = 'Edit_USER';
export const lOGIN_TRUE = 'lOGIN_TRUE';
export const AddTour = 'AddTour';
export const EditTour = 'EditTour';



